<!-- 商业道德 -->
<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/合作与发展banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <h2 class="H2">商业道德</h2>
      <img style="width: 100%;margin-top: 3rem;" src="@/assets/cooperation/commercial_ethics/道德.png" alt="">
      <div class="text">
      
          <img src="@/assets/cooperation/commercial_ethics/商业道德.png" alt="">
      
        <div class="text_1">
          <p style="text-indent: 2em;">{{ text_1 }}</p>
          <p style="text-indent: 2em;">{{ text_2 }}</p>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/合作与发展banner.png" class="images">
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <div class="content">
      <img src="@/assets/cooperation/commercial_ethics/道德.png" alt="" />
      <h2>盛泰-商业道德</h2>
      <p>{{text_1}}</p>
      <p>{{text_2}}</p>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  components: { foot, headers, move_header, move_foot },

  setup() {
    const route = useRoute();
    const state = reactive({
      text_1: '盛泰光电在企业经营行为方面致力于达到法律和道德标准的最高准绳，外部监管与内部规章制度要求相结合，明确本准则的目的是帮助每一位员工了解在商业活动中应遵循什么样的行为方式，以及建立最低行为标准。',
      text_2: '盛泰光电的企业经营活动以及员工行为的规范；是确保盛泰光电长期发展、健康发展、永续经营的基础，为盛泰光电经营过程中所有的商业行为和员工的个人道德准则提供了需遵守的相关活动正式指引。'
    });
    onMounted(() => {
      let text = document.getElementsByClassName('text_1')
      console.log(text);
      setTimeout(()=>{
        text[0].style.display = 'block'
      },500)
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {

  width: 100%;

  .content {
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;
        .H2 {
            margin-top: 50px;
            font-size: 1.7rem;
        }

        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 18%;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }
    .text {
      position: relative;
      box-sizing: border-box;
      margin: 50px 0;
      img{
        width: 100%;
      }
      .text_1 {
        position: absolute;
        left: 300px;top:200px;
        width: 60%;
        // letter-spacing: 4px;
        padding: 50px;
        font-size: 1.2rem;
        display: none;
      }
    }
  }
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .content {
      padding: 30px;

      img {
        width: 100%;
      }
      p{
        font-size: 60px;
      }
            h2 {
                text-align: center;
                font-size: 80px;
            }

            h2::before {
                content: "";
                width: 30%;
                height: 8px;
                background-color: #c7000b;
                position: absolute;
                margin-top: 100px;
                left: 50%;
                transform: translateX(-50%);
                min-width: 100px;
                max-width: 100px;
            }
    }
  }
}
</style>